<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑账号' : '创建账号'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="account">
          <el-input
            v-model.trim="form.data.account"
            maxlength="11"
            placeholder="请输入电话"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!isChange">
          <el-input
            v-model.trim="form.data.password"
            placeholder="请输入密码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select v-model="form.data.role_id" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in roleArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-if="roleArr.filter(v => v.id == form.data.role_id).length > 0">
            <i class="el-icon-info" style="color:#e6a23c;"></i>
            <span style="color:#e6a23c;">{{roleArr.filter(v => v.id == form.data.role_id)[0].desc}}</span>
          </div>
        </el-form-item>
        <el-form-item label="地区" prop="area" v-if="!!form.data.role_id && roleArr.filter(v => v.id == form.data.role_id)[0].is_sys != 1 && city == 0">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import {setStorage,getStorage} from '@/storage/index.js'
  import common from '@/util'
  import { REG_EXP } from '@/util'
  export default {
    name: 'AccountEdit',
    data() {
      return {
        province: getStorage('province'),
        city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        is_sys: this.$store.state.is_sys, // 1:只有超级管理员才有列表的删除按钮
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            account: '',
            password: '',
            area: '',
            role_id: '', //角色id
          },
          rules: {
            name: [{ required: true, message:'请输入姓名', trigger: 'change'}],
            account: [
              { required: true, message:'请输入电话', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: '请输入正确的手机号',
                trigger: 'change'
              }
            ],
            role_id: [{ required: true, message:'请选择角色', trigger: 'change'}],
          }
        },
        areaArr: [],
        roleArr:[],
        
      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        this.getRole();
        if(!!row.id) {
          this.isChange = true
          common.deepClone(this.form.data, row)
          this.form.data.area = [row.province, row.city];
        }
      },
      getRole() {
        this.$http.get('/admin/role/list',{params: {page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            this.roleArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            if(!!this.city) {
              this.form.data.province = this.province;
              this.form.data.city = this.city;
            } else if(this.roleArr.filter(v => v.id == this.form.data.role_id)[0].is_sys == 1) {
              this.form.data.province = '';
              this.form.data.city = '';
            } else {
              this.form.data.province = this.form.data.area[0];
              this.form.data.city = this.form.data.area[1];
            }
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              account: this.form.data.account,
              password: this.form.data.password,
              province: this.form.data.province,
              city: this.form.data.city,
              ity: this.roleArr.filter(v => v.id == this.form.data.role_id)[0].is_sys == 1 ? '' : this.form.data.area[1], // 超级管理员不要地区
              role_id: this.form.data.role_id,
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/admin/edit' : '/admin/admin/create'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
               if(this.isChange == true) {
                  this.getAccess(); // 获取权限菜单列表
                  setTimeout(() => {
                    location.reload();
                  }, 300)
                }
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      getAccess() {
        this.$http.get('/admin/admin/getAccess',{params:{}}).then(res => {
          if(res.code == 1) {
            setStorage('access_list', res.data.access_list); // 该账号的权限菜单列表
            setStorage('is_sys', res.data.is_sys); //是否是系统创建 1:是（超级管理员） 0:否
            setStorage('city', res.data.city)
          }
        })
      },
    }
  }
</script>
