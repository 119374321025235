<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="姓名" prop="name">
          <el-input
            size="mini"
            v-model.trim="table.params.name"
            placeholder="请输入姓名"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="account">
          <el-input
            size="mini"
            v-model.trim="table.params.account"
            placeholder="请输入电话"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建账号</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:area="{row}">
        <span>{{row.province_str}}{{row.city_str}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="handlePwd(row)">修改密码</el-button>
        <!-- 不允许删除当前的登录账号 -->
        <el-button type="text" @click="remove(row)" v-if="login_account != row.account && is_sys == 1">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

    <!-- 修改密码 -->
    <NewPwd ref="newpwd"></NewPwd>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import NewPwd from '@/components/newPwd.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Edit,
    NewPwd,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      login_account: getStorage('account'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "姓名", hidden: false },
        { name: "account", label: "电话", hidden: false},
        { name: "role_name", label: "角色", hidden: false},
        { name: "area", label: "地区", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          account: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      accessArr:{1:'后台端',2:'库管端',3:'配送端'},
    
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.post('/admin/admin/list', this.table.params).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 修改密码
    handlePwd(row) {
      let dom = this.$refs.newpwd
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/admin/delete"
      );
    },
  }
}
</script>
